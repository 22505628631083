<template>
  <div class="container">
    <el-row class="sale-module-query-search flex flex-align-center">
      <div class="tab_menu width_1200 flex">
        <div
          v-for="(item, idx) in tabs"
          :key="idx"
          @click="tab(idx)"
          class="menu_item"
          :class="{ cur: tabsIndex == idx }"
        >
          {{ item.name }}
        </div>
      </div>
    </el-row>
    <div ref="load" class="list">
      <div v-if="list && list.length > 0" class="flex">
        <div
          v-for="item in list"
          :key="item.id"
          class="item-block"
          @click="toDetail(item)"
        >
          <div v-if="tabsIndex == 0">
            <img :src="imgUrl + item.photo_render[0] + '!width_250px'" />
            <div class="name">{{ item.name }}</div>
            <div class="collect">
              <i class="iconfont icon-shoucangxiao"></i>
            </div>
            <div class="feature">
              <span
                style="margin-right: 4px"
                v-if="item.style && item.style.name"
                >{{ item.style.name }} |</span
              >
              <span style="margin-right: 4px" v-if="item.cat && item.cat.name"
                >{{ item.cat.name }} |</span
              >
              {{ item.series && item.series.name }}
            </div>
            <div class="price">
              ¥{{ item.start_price }}
              <span>起</span>
            </div>
            <!-- <div class="watch-count">
                    已有{{ item.statistics && item.statistics.uv }}人查看
                </div> -->
            <div class="comp-info">
              <div class="tit">
                {{ item.factory_comp && item.factory_comp.name }}
              </div>
              <div class="style">
                <span>
                  <i class="el-icon-medal"></i>
                  {{ item.sku_count }}种款式
                </span>
                <span v-if="item.is_proof" class="dayang">
                  <b>
                    <i class="el-icon-check"></i>
                  </b>
                  已打样
                </span>
                <span v-else class="dayang">
                  <b style="border: 1px solid #808080"> </b>
                  未打样
                </span>
              </div>
              <div class="label">
                <label class="color-1">现货</label>
                <label class="color-2">高信用</label>
                <label class="color-3">低价</label>
              </div>
            </div>
          </div>
          <div v-if="tabsIndex == 1">
            <div>
              <img :src="imgUrl + item.photo_render[0] + '!width_250px'" />
              <div v-if="item.name" class="name">{{ item.name }}</div>
              <div class="feature">
                <span
                  style="margin-right: 4px"
                  v-if="item.style && item.style.name"
                  >{{ item.style.name }} |</span
                >
                <span style="margin-right: 4px" v-if="item.cat && item.cat.name"
                  >{{ item.cat.name }} |</span
                >
                {{ item.series && item.series.name }}
              </div>
              <div class="price">
                ¥{{ item.start_price }}
                <span>起</span>
              </div>
              <div class="label">
                <label class="color-1">现货</label>
                <label class="color-2">高信用</label>
                <label class="color-3">低价</label>
              </div>
            </div>

            <div class="comp-check">
              <div class="count">{{ item.cooperatedCount }}</div>
              <span>合作工厂</span>
              <a @click="toCheckFactory()">查看</a>
            </div>
          </div>
        </div>
        <div style="position: absolute; bottom: 20px; right: 20px">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page"
            :page-size="queryOpt.pagesize"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div
        v-if="list && list.length == 0"
        class="flex flex-center"
        style="text-align: center; height: 500px"
      >
        <div>
          <img
            :src="staticUrl + '/design/placeholder/meiyougengduo.png'"
            alt=""
          />
          <div style="font-size: 20px; color: #808080; padding: 15px 0">
            暂无相关数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { listFav } from "@/service/sale";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import { getCats, getStyles, materialList } from "@/service/item";
import { fetchTree } from "@/util";
export default {
  components: {},
  data() {
    return {
      catId: null,
      config: {
        styles: null, // 风格
        materials: null, // 材质
        cats: null, //类目
      },
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      tabs: [{ name: "工厂款" }, { name: "设计师合作款" }],
      tabsIndex: 0,
      queryOpt: {
        type: 0, // 0工厂款，1设计师合作款
        page: 1, // 页码，默认1
        pagesize: 16, // 每页记录数量，默认15
      },
      total: 0,
      list: null,
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  created() {
    this.refreshItems();
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
  },
  methods: {
    toDetail(item) {
      if (this.tabsIndex == 0) {
        this.$router.push(`/sale/mall/home/factory_detail/${item.id}`);
      } else if (this.tabsIndex == 1) {
        this.$router.push(`/sale/mall/home/design_detail/${item.design_product_no}`);
      }
    },
    goto(path) {
      this.$router.push(path);
    },
    // 选项卡-版权购买-分成合作
    tab(index) {
      this.tabsIndex = index;
      this.queryOpt.type = index;
    },
    refreshItems() {
      this.list = null;
      const _loading = this.$loading({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$refs.load,
      });
      listFav(this.queryOpt)
        .then((rst) => {
          this.total = rst.total;
          this.list = rst.list;
          console.log("listFav", this.list);
          _loading.close();
        })
        .catch((err) => {
          _loading.close();
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto 40px;
}
.banner {
  height: 340px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}
.tab_menu {
  .menu_item {
    height: 60px;
    line-height: 60px;
    margin-right: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #4d4d4d;
    &.cur {
      color: #5074ee;
      border-bottom: 2px solid #5074ee;
    }
  }
}
.list {
  min-height: 800px;
  padding: 30px 30px 40px 30px;
  background-color: #fff;
  position: relative;
  .item-block {
    width: 264px;
    height: 482px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(204, 204, 204, 1);
    padding: 20px;
    color: #808080;
    margin: 0 28px 30px 0;
    transform: scale(1);
    transition: all 0.3s;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(80, 116, 238, 1);
      transform: scale(1.064);
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    cursor: pointer;
    img {
      width: 224px;
      height: 224px;
    }
    .name {
      color: #292929;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 20px;
    }
    .collect {
      position: absolute;
      top: 280px;
      right: 20px;
      i {
        font-size: 16px;
        color: #5074ee;
      }
    }
    .feature {
      color: #808080;
      font-size: 12px;
      margin-top: 10px;
    }
    .price {
      color: #ee1818;
      font-size: 20px;
      margin-top: 10px;
      span {
        color: #808080;
        font-size: 12px;
      }
    }
    .watch-count {
      color: #b3b3b3;
      font-weight: 500;
      margin-top: 10px;
    }
    .label {
      margin-top: 10px;
      display: flex;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 2px;
        padding: 0 3px;
        margin-right: 9px;
        &.color-1 {
          color: #fff;
          background-color: #f66f6a;
        }
        &.color-2 {
          color: #5074ee;
          border: 1px solid #5074ee;
        }
        &.color-3 {
          color: #2bbaae;
          border: 1px solid #2bbaae;
        }
      }
    }
    .comp-info {
      margin-top: 10px;
      .tit {
        color: #292929;
        padding-top: 14px;
        border-top: 1px solid #ccc;
        font-weight: bold;
      }
      .style {
        font-size: 12px;
        margin-top: 10px;
        display: flex;
        span {
          margin-right: 18px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          &:first-child {
            i {
              font-size: 14px;
            }
          }
        }
        .dayang {
          display: flex;
          align-items: center;
          white-space: nowrap;
          b {
            width: 12px;
            height: 12px;
            border: 1px solid #5074ee;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            i {
              font-size: 14px;
              color: #5074ee;
            }
          }
        }
      }
    }
    .comp-check {
      padding: 14px 20px;
      margin-top: 20px;
      border-top: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;
      .count {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #365fe1;
        color: #365fe1;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
      }
      span {
        color: #808080;
      }
      a {
        position: absolute;
        right: 20px;
      }
    }
  }
}
</style>
